import * as React from 'react';
import mapboxgl from "mapbox-gl";
import moment from 'moment';

import * as turf from "@turf/turf";
import { useNavigate } from 'react-router-dom';



mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';


const addCircle = (map, placeData) => {
    placeData.forEach((obj, idx) => {
        ReturnCircle(parseFloat(obj.lat), parseFloat(obj.lng), obj.rad, obj.label, map)
    })
}

const ReturnCircle = (lat, lon, rad, location, map) => {
    
    if (!parseFloat(lat) || !parseFloat(lon)) {
        return;
    }
    var radius = (rad/1000);
    var center = [parseFloat(lon), parseFloat(lat)];
    var options = { steps: 50, units: "kilometers", properties: {'title': 'Mapbox DC'} };
    var circle = turf.circle(center, radius, options);
    map.addSource("circleData" + location, {
        type: "geojson",
        data: circle,
    });

    map.addLayer({
        id: "circle-fill" + location,
        type: "fill",
        //"minzoom":16,
        source: "circleData" + location,
        paint: {
            "fill-color": "#0f7377",
            "fill-opacity": 0.2,
        }
    });
}

const getData = (deviceData, search) => {
    
    if (Array.isArray(search) && search.length !== 0) {
        if(!search[0].latest || !Object.keys(search[0].latest).length)return false;
        //var jarr = {};
        const groupBY =search.filter((g)=>{ return g.type === "Group";})
        const assetBY =search.filter((g)=>{ return g.type === "Asset";})

        const gData = groupBY.length?deviceData.filter((item) => {
            return groupBY.map((ji)=>{ return ji.id}).includes(item.groupID)
        }):[];

        const aData = assetBY.length?deviceData.filter((item) => {
            return assetBY.map((ji)=>{ return ji.id}).includes(item.id)
        }):[];

        return [...aData, ...gData].map((item) => (
            {
                id: item.id,
                label: item.label,
                device: item.device,
                latest: item.latest
            }));
    }
    else {

        const dt = deviceData.filter((d)=> {return d.latest && Object.keys(d.latest).length}) ;

        return dt.map((item) => ({
            id: item.id,
            label: item.label,
            device: item.device,
            latest: item.latest
        }));
    }
}


/*const popup = new mapboxgl.Popup({
    closeButton: true,
    closeOnClick: false
});*/

const popContaint = (popupInfo, add) => {
    let html = "";
    html+="<div style='width:185px;margin: auto;padding:0px;'><div style='display: flex;'><span style='font-size:11px;font-weight:bold;margin-left:70px;'>" + popupInfo.name + "</span><span title='History' id='historySpan' style='margin-left:20px;cursor:pointer;color:grey;font-size:18px;' class='material-icons'>trending_up</span><span title='Edit' id='editSpan' style='margin-left:10px;cursor:pointer;color:grey;font-size:18px;' class='material-icons'>edit</span></div><div></div><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;display: flex;justify-content: center;'>" + add + "</span><div style=''></div>";
    html+= (popupInfo.duration)?"<hr style='margin: 0em;border-width: 1px;'></hr><span style='font-size:11px;display: flex;justify-content: center;'> For " + popupInfo.duration + "</span>":"";
    return html;
}



export default function Maps({ search, openChart, deviceDataList, table2Map, Map2Table, places}) {
    //console.log("deviceDataList",deviceDataList);
    const map = React.useRef(0);
    const oldChart = React.useRef(0);
    const mapContainerRef = React.useRef(0);
  //  const [popInfo, setPopInfo] = React.useState(false);
    const [M2T, setM2T] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [nav, setNav] = React.useState(false);
    const [chart, setChart] = React.useState(false);
    //const [deviceList] = React.useState(deviceDataList.device);
    //const [mapToTable, setMaptoTable] = React.useState(false);
    //const [deviceData] = React.useState(deviceDataList);
    const[deviceData, setDeviceData] =  React.useState(getData(deviceDataList, search, Map2Table))
    const preSearch = React.useRef(search);
    //const prevInfo = React.useRef(0);
    const tableToMap = React.useRef(table2Map);
    const navigate = useNavigate()
    
    React.useEffect(()=>{
        if(nav){
            console.log("here nav");
            navigate(nav);
        }   

    },[nav, navigate])


    React.useEffect(()=>{
        if(chart && oldChart.current !== chart){
            console.log("here chart");
            openChart(chart);
       
            oldChart.current = chart;
        }
    },[chart, openChart])
    
    const popWindow = (row, coordinates, map) => {
        //console.log(row);
    
        const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false,
            offset: [0, -8]
        });
        const popups = document.getElementsByClassName("mapboxgl-popup");
        if (popups.length) popups[0].remove();
        
        if (row.place.indexOf(',') === -1) {
            const add = row.place;
            popup.setLngLat(coordinates).setHTML(popContaint(row,add)).addTo(map);
    
            document.getElementById("historySpan").addEventListener("click",function(){
                //openChart({ id: key, assetName: multi[x].label });
                setChart({ id: row.id, assetName: row.name });
            });
            document.getElementById("editSpan").addEventListener("click",function(){
                setNav("/Asset?id="+row.name);
            });
    
        } else {
    
            const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${coordinates[1]},${coordinates[0]}`;
    
            fetch(url).then(response => { return response.json() })
                .then(location => {
                    const place = location.results[0];
                    const add = place.formatted_address;
                    popup.setLngLat(coordinates).setHTML(popContaint(row,add)).addTo(map);

                    document.getElementById("historySpan").addEventListener("click",function(){
                        //openChart({ id: key, assetName: multi[x].label });
                        setChart({ id: row.id, assetName: row.name });
                    });
                    document.getElementById("editSpan").addEventListener("click",function(){
                        setNav("/Asset?id="+row.name);
                    });
                });
        }
    }

    React.useEffect(()=>{
        //console.log("load once");
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [-98.35,39.50],

            //center: [-98,35,39.50],
            //zoom: 3,
            zoom: 15,
            pitch: 45,
            bearing: -17.6,
            //container: 'map',
            antialias: true
        });

        map.current.on("load", function () {
            //if(deviceData.length)
            //console.log("loading map");
            setLoaded(true);
            map.current.resize();

            const layers = map.current.getStyle().layers;
            const labelLayerId = layers.find(
                (layer) => layer.type === 'symbol' && layer.layout['text-field']
            ).id;

            // The 'building' layer in the Mapbox Streets
            // vector tileset contains building height data
            // from OpenStreetMap.
            map.current.addLayer(
                {
                    'id': 'add-3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                        'fill-extrusion-color': '#aaa',

                        // Use an 'interpolate' expression to
                        // add a smooth transition effect to
                        // the buildings as the user zooms in.
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'height']
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            15.05,
                            ['get', 'min_height']
                        ],
                        'fill-extrusion-opacity': 0.6
                    }
                },
                labelLayerId
            );
            map.current.addControl(new mapboxgl.NavigationControl());
            //window.dispatchEvent(new Event('resize'));
            map.current.on('resize', () => {
                map.current.resize();
            });
            addCircle(map.current, places)

            var pData = [];
            for (var k in places) {
                if (places[k].lng && places[k].lat) {
                    pData.push({ "type": "Feature", "properties": { "id": places[k].name, "name": places[k].label }, "geometry": { "type": "Point", "coordinates": [places[k].lng, places[k].lat, 0.0] } })
                }
                //      k++;
                //}
            }

            map.current.addSource('places', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": pData
                },
                cluster: true,
                clusterMaxZoom: 19, // Max zoom to cluster points on
                clusterRadius: 50 // 
            });

            map.current.addLayer({
                id: 'places_label',
                //type: 'circle',
                type: 'symbol',
                source: 'places',
                //"minzoom":16,
                filter: ['!', ['has', 'point_count']],
                'layout': {
                    //"icon-image": symbol + "-15",
                    "text-allow-overlap": true,
                    "text-field": ["string", ['get', 'name']],
                    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    "text-size": 13,
                    //"text-transform": "uppercase",
                    "text-letter-spacing": 0.05,
                    "text-offset": [0, -4]
                },
                "paint": {
                    "text-color": "#0f7377",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "text-halo-blur":1
                }
            });
        });

        return () => map.current.remove();
    },[places])
    
    React.useEffect(()=>{
    
        if(preSearch.current !==  search){
            setDeviceData(getData(deviceDataList, search))

        const layrs = ["clusters","unclustered-point","tile_label","cluster-count","tilesSingle_newLabel","tilesSingle-newPoint"];
            const src = ["tiles","tilesSingle"];
            for(var m in layrs)
                if(map.current.getLayer(layrs[m])){
                    //console.log("removing layer", layrs[m])
                    map.current.removeLayer(layrs[m]);
                }
            
            for(var x in src)
                if(map.current.getSource(src[x])){
                    //console.log("removing src ", src[x]);
                    map.current.removeSource(src[x]);
                }   

                preSearch.current = search;
        }
        
    },[search, deviceDataList])

    React.useEffect(() => {
        //console.log("loading again", [deviceData[0].latest.lng, deviceData[0].latest.lat]);

        if(loaded === false || !deviceData.length)return;
        map.current.setCenter([deviceData[0].latest.lng, deviceData[0].latest.lat]);
        const bounds = new mapboxgl.LngLatBounds([(deviceData[0].latest.lng?deviceData[0].latest.lng:-98.35), (deviceData[0].latest.lat?deviceData[0].latest.lat:39.50)], [(deviceData[0].latest.lng?deviceData[0].latest.lng:-98.35), (deviceData[0].latest.lat?deviceData[0].latest.lat:39.50)]);

        for (var j in deviceData) {
            //console.log(deviceData[j])
            if(deviceData[j].latest.lng && deviceData[j].latest.lat){
                bounds.extend([deviceData[j].latest.lng, deviceData[j].latest.lat]);
            }   
        }

        map.current.fitBounds(bounds, {
            //clusterMaxZoom:16,
            maxZoom: 17,
            padding: 50,
            bearing: 17.6,
            pitch: 45,
        });

        
        if(loaded){
            
            var Gdata = [];
    
            for (var y in deviceData) {
                if (deviceData[y].latest.lng && deviceData[y].latest.lat) {
                    //console.log(deviceData[y].latest.dur);
                    let place = (deviceData[y].latest.place)?deviceData[y].latest.place.label:deviceData[y].latest.lng+","+deviceData[y].latest.lat;
                    Gdata.push({ "type": "Feature", "properties": { "id": deviceData[y].id, "name": deviceData[y].label,"place":place, "checkIn": moment.unix(deviceData[y].latest.utc).fromNow(), "duration": (deviceData[y].latest.dur)?moment().subtract(deviceData[y].latest.dur, 'seconds').fromNow(true):"", "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [deviceData[y].latest.lng, deviceData[y].latest.lat, 0.0] } })
                }
            }

            map.current.addSource('tiles', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                data: {
                    "type": "FeatureCollection",
                    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                    "features": Gdata
                },
                cluster: true,
                clusterMaxZoom: 18, // Max zoom to cluster points on
                clusterRadius: 50 // 
            });

            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'tiles',
                filter: ['has', 'point_count'],
                paint: {
                    // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 30px circles when point count is between 100 and 750
                    //   * Pink, 40px circles when point count is greater than or equal to 750
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#c6f175', 10,
                        '#51bbd6', 50,
                        '#f175af', 750,
                        '#f28cb1'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        30,
                        35,
                        40
                    ],
                    //"icon-allow-overlap": true
                }
            });

            map.current.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                //type: 'symbol',
                source: 'tiles',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#3d9195',
                    'circle-radius': 7,
                    'circle-stroke-width': 3,
                    'circle-stroke-color': 'black',
                    'circle-opacity': 0.6

                }
                /*layout: {
                    "icon-image": "cat"
                },*/

            });


            map.current.addLayer({
                id: 'tile_label',
                //type: 'circle',
                type: 'symbol',
                source: 'tiles',
                filter: ['!', ['has', 'point_count']],
                'layout': {
                    //'text-field': "test",
                    
                    'text-field': ["string", ['get', 'name']],
                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                    'text-size': 10,
                    //'text-letter-spacing': 0,
                    //'text-halo-width':0,
                    //'text-emissive-strength':0,
                    //'text-allow-overlap': true,
                    //"text-transform": "uppercase",
                    "text-letter-spacing": 0.05,
                    'text-offset': [0, 1.8],
                },
                'paint': {
                    "text-color": "#0f7377",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "text-halo-blur":1
                }
                /*layout: {
                    "icon-image": "cat"
                },*/

            });

            
            map.current.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'tiles',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 18,
                    'text-allow-overlap': true
                }
            });
            
            // inspect a cluster on click
            map.current.on('click', 'clusters', (e) => {
                e.clickOnLayer = true;

                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });
                const clusterId = features[0].properties.cluster_id;

                const pointCount = features[0].properties.point_count;
                const clusterSource = map.current.getSource('tiles');
                //console.log("clusterId",clusterId);
                if (map.current.getZoom() < 18) {
                    map.current.getSource('tiles').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;
                                map.current.flyTo({
                                    center: features[0].geometry.coordinates,
                                    zoom: zoom
                                });
                            }
                        );    
                    /*clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                            console.log("here");
                            map.current.flyTo({
                                //center: [e.lngLat.lng, e.lngLat.lat],//features[0].geometry.coordinates,
                                center: features[1].geometry.coordinates,
                                //center: [deviceData[0].lng, deviceData[0].lat],
                                zoom: 16,
                                duration: 1000
                                //zoom: 16
                            });
                        })*/
                }
                    clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                        // Print cluster leaves in the console
                        setM2T(features.map((item) => {
                            return item.properties.id
                        }));
                    })
                //}

            });

            map.current.on('click', function (e) {
                if (e.clickOnLayer) {
                    return;
                }
                setM2T(false)
            });

            // When a click event occurs on a feature in
            // the unclustered-point layer, open a popup at
            // the location of the feature, with
            // description HTML from its properties.
            map.current.on('click', 'unclustered-point', (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                //const mag = e.features[0].properties.mag;
                //const asset = e.features[0].properties.name;
                //const id = e.features[0].properties.id;
                //const checkIn = e.features[0].properties.checkIn;
                //const Duration = e.features[0].properties.duration;
                //const tsunami = e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popWindow(e.features[0].properties,coordinates,map.current);
                /*const popups = document.getElementsByClassName("mapboxgl-popup");
                if (popups.length) popups[0].remove();

                const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${e.lngLat.lat},${e.lngLat.lng}`;
                fetch(url).then(response => { return response.json() })
                    .then(location => {
                        const place = location.results[0];
                        const add = place.formatted_address;
                        //console.log(Duration);
                        let html = "";
                        html+="<div style='width:185px;margin: auto;padding:0px;'><span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + asset + "</span><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + add + "</span><hr style='margin: 0em;border-width: 1px;'><div style=''></div>";
                        html+= (Duration)?"<span style='font-size:11px;'></span> <span style='font-size:11px;font-weight:bold;'>" + Duration + "</span><hr style='margin: 0em;border-width: 1px;'></hr>":"";
                        
                        new mapboxgl.Popup()
                            .setLngLat(coordinates)
                            .setHTML(html)
                            .addTo(map.current);
                    });*/

            });

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer';
            });
            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = '';
            });
        }
        // Clean up on unmount
        

    }, [deviceData, loaded, places]);

    React.useEffect(() => {
        Map2Table(M2T)
    }, [M2T, Map2Table])

    React.useEffect(() => {
        //var event = "";

        const assignVal = ()=>{
         //   preSearch.current = search;
           // prevInfo.current = popInfo;
            tableToMap.current = table2Map;
        }
         if(!table2Map){
            assignVal();
            return;
         }
//  console.log(table2Map)
        //console.log("search",search);
        if (tableToMap.current !== table2Map ){

         
        
            var Gdata = [];
            for(let a in deviceData){
                //console.log(deviceData[a])
                if(deviceData[a].id === table2Map[0].id){
                    let place = (deviceData[a].latest.place)?deviceData[a].latest.place.label:deviceData[a].latest.lng+","+deviceData[a].latest.lat;
                    Gdata.push({ "type": "Feature", "properties": { "id": deviceData[a].id, "name": deviceData[a].label, "place":place, "checkIn": moment.unix(deviceData[a].latest.utc).fromNow(), "duration": (deviceData[a].latest.dur)?moment().subtract(deviceData[a].latest.dur, 'seconds').fromNow(true):"", "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [deviceData[a].latest.lng, deviceData[a].latest.lat, 0.0] } })
                }
            }

            if(Gdata.length){
    
                const layrs = ["tilesSingle_newLabel","tilesSingle-newPoint"];
                const src = ["tilesSingle"];
                for(var m in layrs)
                    if(map.current.getLayer(layrs[m])){
                        //console.log("removing layer", layrs[m])
                        map.current.removeLayer(layrs[m]);
                    }
                
                for(var x in src)
                    if(map.current.getSource(src[x])){
                        //console.log("removing src ", src[x]);
                        map.current.removeSource(src[x]);
                    }   
            //console.log(Gdata)
                
                map.current.flyTo({
                    center: Gdata[0].geometry.coordinates, zoom: 18, duration: 2000, // Animate over 12 seconds
                    essential: true
                })
                //map.current.setCenter(Gdata[0].geometry.coordinates);
                map.current.addSource('tilesSingle', {
                    type: 'geojson',
                    // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                    // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                    data: {
                        "type": "FeatureCollection",
                        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                        "features": Gdata
                    },
                    cluster: true,
                    clusterMaxZoom: 19, // Max zoom to cluster points on
                    clusterRadius: 50 // 
                });

                map.current.addLayer({
                    id: 'tilesSingle-newPoint',
                    type: 'circle',
                    //type: 'symbol',
                    source: 'tilesSingle',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#3d9195',
                        'circle-radius': 7,
                        'circle-stroke-width': 3,
                        'circle-stroke-color': 'black',
                        'circle-opacity': 0.6

                    }
                    /*layout: {
                        "icon-image": "cat"
                    },*/

                });

                map.current.addLayer({
                    id: 'tilesSingle_newLabel',
                    //type: 'circle',
                    type: 'symbol',
                    source: 'tilesSingle',
                    filter: ['!', ['has', 'point_count']],
                    'layout': {
                        //'text-field': "test",
                        'text-field': ["string", ['get', 'name']],
                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                        'text-size': 10,
                        //'text-letter-spacing': 0,
                        //'text-halo-width':0,
                        //'text-emissive-strength':0,
                        'text-allow-overlap': true,
                        //"text-transform": "uppercase",
                        "text-letter-spacing": 0.05,
                        'text-offset': [0, 1.8],
                    },
                    'paint': {
                        "text-color": "#0f7377",
                        "text-halo-color": "#fff",
                        "text-halo-width": 1,
                        "text-halo-blur":1
                    }
                });
                popWindow(Gdata[0].properties,Gdata[0].geometry.coordinates, map.current);
                /*new mapboxgl.Popup()
                .setLngLat(Gdata[0].geometry.coordinates)
                .setHTML("hi")
                .addTo(map.current);*/
            }
        }
        assignVal();
        //const marker = new mapboxgl.Marker().setLngLat([table2Map[i][0].lng, table2Map[i][0].lat]).addTo(map.current);

        //console.log(search);
        //console.log("OPENING POPUP==============================");h
        //let key = "";
        //var Obj = {};
        //var multi = [];
        //console.log(tableToMap.current, table2Map);
        /*if (preSearch.current !== search) {
            //console.log("1", search)
            //if(search && search.length && search[0].type === "Group")return;
            if (search && search.length) {
                event = "search";
                key = search[0].id;
                let obj = {};
                if(search[0].type === "Group"){
                    event = "group";    
                    for (let i in deviceData) {
                        if (search[0].id === deviceData[i].groupID) {
                            multi.push(deviceData[i]);
                        }                        
                    }
                    
                }else{
                    for (let i in deviceData) {
                        if (deviceData[i].id === search[0].id) {
                            obj = deviceData[i];

                        }
                    }
                }
                
                Obj = {
                    [search[0].id]: obj,
                    name: search[0].label
                }
            }
            if(search.length && search[0].type === "Group" && multi.length === 0){
                assignVal();
                return;
            }
        }
        else if (tableToMap.current !== table2Map) {
            //console.log("2")
            if (table2Map && table2Map.length) {
                //console.log("table2Map1",table2Map);
                event = "tableMap";
                key = table2Map[0].id;
                //console.log(key)
                let obj = {};
                for (let i in deviceData) {
                    if (deviceData[i].id === table2Map[0].id) {
                        //console.log("here", deviceData[i])
                        obj = deviceData[i];
                    }
                }
                //console.log(table2Map[0])
                if (!Object.keys(obj).length){
                    assignVal();
                    return;
                }
                Obj = {
                    [key]: obj,
                    name: table2Map[0].assetName
                }
                //console.log(Obj)
            }
            //console.log("Obj",Obj);
        }
        else if (prevInfo.current !== popInfo) {
            //console.log("3")
            //console.log("PopInfo", typeof popInfo);
            if (popInfo && typeof popInfo === "object") {
                //console.log("openPop")
                event = "popInfo";
                key = Object.keys(popInfo)[0];
                for (var j in deviceData) {
                    if (key === deviceData[j].id) {
                        //console.log(deviceList["list"][j]);
                        Obj = Object.assign({}, popInfo, { "name": deviceData[j].label });;
                    }
                }
            }
        }

        //console.log(Obj, Object.keys(Obj).length);

        if (event !== "popInfo") {
            const popContaint = (popupInfo, addr) => {
                let keyJ = Object.keys(popupInfo)[0];
                let name = (popupInfo.name)?popupInfo.name:popupInfo.label;
                let html = "";
                //moment().subtract(item.dur, 'seconds').fromNow(true)
                html += "<div style='width:185px;margin: auto;padding:0px;'><span style='font-size:11px;'>Asset Name: </span> <span style='font-size:11px;font-weight:bold;'>" + name + "</span><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;'>Place: </span> <span style='font-size:11px;font-weight:bold;'>" + addr + "</span><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;'>Check In: </span> <span style='font-size:11px;font-weight:bold;'>" + moment.unix(popupInfo[keyJ].latest.utc).fromNow() + "</span><hr style='margin: 0em;border-width: 1px;'><span style='font-size:11px;'>Duration: </span> <span style='font-size:11px;font-weight:bold;'>" + moment().subtract(popupInfo[keyJ].latest.dur, 'seconds').fromNow(true) + "</span><hr style='margin: 0em;border-width: 1px;'><div style=''><Button id='getHistory"+keyJ+"' style='margin-top:10px;height:30px;width:95%;background-color:#0f7377;color:#fff;cursor:pointer;'>History</Button></div>"
                return html;
            }
            //console.log(Obj);
            const popups = document.getElementsByClassName("mapboxgl-popup");
            if (popups.length) popups[0].remove();


            if (Object.keys(Obj).length === 0){
                assignVal();
                return;
            }
            //console.log("Object.keys(Obj).length", Obj);
            if (event === "search" || event === "tableMap") {
                if(Obj[key].latest.lng && Obj[key].latest.lat){
                    map.current?.flyTo({
                        center: [Obj[key].latest.lng, Obj[key].latest.lat], zoom: 18, duration: 2000, // Animate over 12 seconds
                        essential: true
                    })
                }
            }
            if(multi.length){
                for(var x in multi){

                    // map.current?.flyTo({
                    //     center: [multi[x].latest.lng, multi[x].latest.lat], zoom: 19, duration: 2000, // Animate over 12 seconds
                    //     essential: true
                    // })
                    (function(x) {

                    if (multi[x].latest.place && multi[x].latest.place.label) {
                        const add = multi[x].latest.place.label;
                        if(multi[x].latest.lng && multi[x].latest.lat){
                            popup.setLngLat([multi[x].latest.lng, multi[x].latest.lat]).setHTML(popContaint({[multi[x].id]:multi[x]}, add)).addTo(map.current);
                            let key = multi[x].id;
                            document.getElementById("getHistory"+key+"").addEventListener("click", () => {
                                openChart({ id: key, assetName: multi[x].label });
                            }, false);
                        }
                    } else {
                        //console.log("here");
                        if(multi[x].latest.lng && multi[x].latest.lat){
                        const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${multi[x].latest.lat},${multi[x].latest.lng}`;
                        fetch(url).then(response => { return response.json() })
                            .then(location => {
                                const place = location.results[0];
                                const add = place.formatted_address;
                                popup.setLngLat([multi[x].latest.lng, multi[x].latest.lat]).setHTML(popContaint({[multi[x].id]:multi[x]}, add)).addTo(map.current);
                                let key = multi[x].id;
                                document.getElementById("getHistory"+key+"").addEventListener("click", () => {
                                    openChart({ id: key, assetName: multi[x].label });
                                }, false);
    
                            });
                        }
                    } 
                })(x);
   
                }
            }
            else{
                if (Obj[key].latest.place && Obj[key].latest.place.label) {
                    const add = Obj[key].latest.place.label;
                    if(Obj[key].latest.lng && Obj[key].latest.lat){
                        popup.setLngLat([Obj[key].latest.lng, Obj[key].latest.lat]).setHTML(popContaint(Obj, add)).addTo(map.current);

                        document.getElementById("getHistory"+key+"").addEventListener("click", () => {
                            openChart({ id: key, assetName: Obj.name });
                        }, false);
                    }
                } else {
                    //console.log("here");
                    if(Obj[key].latest.lng && Obj[key].latest.lat){
                    const url = `${geocodeJson}?key=AIzaSyA3uW7wxKiCq8DzbS2pBuYuiufuKQ_neDM&latlng=${Obj[key].latest.lat},${Obj[key].latest.lng}`;
                    fetch(url).then(response => { return response.json() })
                        .then(location => {
                            const place = location.results[0];
                            const add = place.formatted_address;
                            popup.setLngLat([Obj[key].latest.lng, Obj[key].latest.lat]).setHTML(popContaint(Obj, add)).addTo(map.current);

                            document.getElementById("getHistory"+key+"").addEventListener("click", () => {
                                openChart({ id: key, assetName: Obj.name });
                            }, false);

                        });
                    }
                }
            }
        } else {
            openChart({ id: key, assetName: Obj.name });
        }

        assignVal();*/

    }, [deviceData, openChart, table2Map])
    

    return <div style={{ height: "100%", width: "100%" }} ref={mapContainerRef} ><div id="zoomvalue"></div><div id="zoombehavior"></div></div>;
}