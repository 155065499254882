import './App.css';
import React from 'react';
import Login from './login';
import Loader from './components/loader';
import Dashboard from './components/dashboard/dashboard';
import Places from './components/place/places';
import Asset from './components/asset/asset';
import Group from './components/group/group';
//import Navbar from './components/navbar';
import { useSelector } from 'react-redux'


import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

const theme = createTheme({
    palette: {
        root: {
            color: 'darkslategray',
        },
        primary: {
            //main: "#0f7377"
            main: "#0098a4"
        },
        secondary: {
            //main: "#3d9195"
            main:"#00b4c4"
            //main:"#223f41"
        },
        text: {
            primary: "#000"
        }
    }
});

function App() {

    const { user } = useSelector(state => state.user)
    const { loading, error } = useSelector((state) => state.api);
    const [show, setShow] = React.useState(false);
    const handleClose = (event, reason) => {
        // setOpen(false);
        console.log("closeing")
        setShow(false)
        
        //console.log("closed");
    };

    React.useEffect(() => {
        if (error) setShow(true);
    }, [error])
    //console.log(loading,error);
    return (
        //console.log(Date.now(), user.login.exp * 1000),
        <ThemeProvider theme={theme}>
        <Loader show={loading}></Loader>
        <div className="App">
        <Snackbar
                open={show}

                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                //autoHideDuration={3000}

                onClose={handleClose}
                message={error}
                action={
                    <Button onClick={handleClose} size="medium">
                      Ok
                    </Button>
                }
            //action={action}
            />
            <BrowserRouter>
            <Routes>
                {(Date.now() < (user && user.login && user.login.exp && user.login.exp * 1000)) ? (
                    <>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/Dashboard' element={<Dashboard />} />
                        <Route path='/Place' element={<Places />} />
                        <Route path='/Asset' element={<Asset />} /> 
                        <Route path='/Group' element={<Group />} />  
                    </>
                ) : (
                    <Route path='*' element={<Login />} />
                )}
                </Routes>
            </BrowserRouter>
        </div>
        </ThemeProvider>
    )
}

export default App;
