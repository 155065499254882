import * as React from 'react';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
//import Paper from '@mui/material/Paper';
//import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';
//import Modal from '@mui/material/Modal';
//import rows from '../JSON/us_states.json';
//import Container from '@mui/material/Container';
//import TextField from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { getAPI } from "../../store/api";
//import useMediaQuery from '@mui/material/useMediaQuery';
//import LocationPicker from './locationPicker_old';
import { useDispatch } from 'react-redux'
import Snackbar from '@mui/material/Snackbar';
//import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
//import LocationPicker from '../lcoationPicker/locationPicker';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Navbar from '../navbar';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonGroup from '@mui/material/ButtonGroup';
import mapboxgl from "mapbox-gl";
//import PickerNewMap from '../lcoationPicker/pickerMap';
//import { AddressAutofill, AddressMinimap, useConfirmAddress, config } from '@mapbox/search-js-react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as turf from "@turf/turf";
import _ from 'lodash';
//import { getConfig } from '@testing-library/react';
//import { current } from '@reduxjs/toolkit';
//import { Input } from '@mui/material';

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ReturnCircle = (lat, lon, rad, map) => {
    //lon = -98.35; lat = 39.50; 

    if (map.getLayer("circle-fill")) {
        map.removeLayer("circle-fill");
    }
    
    if (map.getSource("circleData")) {
        map.removeSource("circleData");
    }
    

    if (!parseFloat(lat) || !parseFloat(lon)) {
        //console.log(lat,lon);
        return;
    }
    var radius = (rad/1000);
    var center = [parseFloat(lon), parseFloat(lat)];
    var options = { steps: 50, units: "kilometers", properties: { foo: "bar" } };
    var circle = turf.circle(center, radius, options);

    //console.log("loading circles",rad);
    //console.log(circle,options)
    //console.log(circle);
    //var line = turf.lineString(...circle.geometry.coordinates);
    map.addSource("circleData", {
        type: "geojson",
        data: circle,
    });

    map.addLayer({
        id: "circle-fill",
        type: "fill",
        //"minzoom":16,
        source: "circleData",
        paint: {
            "fill-color": "#0f7377",
            "fill-opacity": 0.2,
        }
    });

}



const PickerMap = ({ cords, newCords }) => {
    //console.log("cords", cords);
    //console.log("loading picker")
    //const [loc, setLoc] = React.useState(cords)
    const [newLoc, setNewLoc] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)
    const mapContainerRef = React.useRef(0);
    const prevCords = React.useRef();
    const prevNewLoc = React.useRef(0);
    const map = React.useRef(0);
    const marker = React.useRef(0);
    const geoCoder = React.useRef(0);
    const isLatitude = num => isFinite(num) && Math.abs(num) <= 90;
    const isLongitude = num => isFinite(num) && Math.abs(num) <= 180;

    /*const onDragEnd = React.useCallback(() => {
        console.log("dragging");
        const lngLat = marker.current.getLngLat();
        setNewLoc({ lat: lngLat.lat.toFixed(7), lng: lngLat.lng.toFixed(7), rad:newLoc.rad})
        
    },[newLoc])


    const funResult = React.useCallback((e)=> {
        console.log(e.result.center);
        geoCoder.current.clear();
        map.current.setCenter(e.result.center)
        marker.current.setLngLat(e.result.center).addTo(map.current)
        setNewLoc({ lat: e.result.center[1], lng: e.result.center[0], rad:newLoc.rad})
    },[newLoc]);*/

    React.useEffect(() => {
        //if(prevNewLoc.current)return;
        //console.log("loading map picker compo")
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [-98.35, 39.50],
            zoom: 12,
        });
        marker.current = new mapboxgl.Marker({ draggable: true }).setLngLat([-98.35, 39.50]).addTo(map.current)

        geoCoder.current = new MapboxGeocoder({
            accessToken: accessToken,
            mapboxgl: mapboxgl,
            reverseGeocode:true
        })
        
        const onDragEnd = () => {
            //console.log("dragging");
            const lngLat = marker.current.getLngLat();
            setNewLoc({lat: lngLat.lat.toFixed(7), lng: lngLat.lng.toFixed(7)})
            
        }
    
        const funResult = (e)=> {
            //console.log(e.result.center);
            geoCoder.current.clear();
            map.current.setCenter(e.result.center)
            marker.current.setLngLat(e.result.center).addTo(map.current)
            setNewLoc({lat: e.result.center[1], lng: e.result.center[0]})
        };

        geoCoder.current.on('result', funResult);

        map.current.addControl(geoCoder.current);

        marker.current.on('dragend', onDragEnd);

        map.current.on("load", function () {
            setLoaded(true)
        });

        map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
        // Clean up on unmount
        return () => map.current.remove();

    }, [])

    React.useEffect(() => {
//        console.log(prevCords.current, cords)
        //if(_.isEqual(cords,loc) === false){
            if(!_.isEqual(prevCords.current, cords)){
                //console.log("testing again")
                //console.log(prevCords.current, cords)
                setNewLoc(cords)
                prevCords.current = cords;
            }
            
        //}
        
    }, [cords])

    React.useEffect(() => {
        //console.log("change", newLoc)
        if(loaded){
            //console.log("New location", newLoc)
            map.current.setCenter([isLongitude(newLoc.lng) ? newLoc.lng : -98.35, isLatitude(newLoc.lat) ? newLoc.lat : 39.50])
            marker.current.setLngLat([isLongitude(newLoc.lng) ? newLoc.lng : -98.35, isLatitude(newLoc.lat) ? newLoc.lat : 39.50]);
            ReturnCircle(isLatitude(newLoc.lat) ? newLoc.lat : "39.50", isLongitude(newLoc.lng) ? newLoc.lng : "-98.35", newLoc.rad?newLoc.rad:"500", map.current)
        }
        //if(marker.current)ReturnCircle("39.50", "-98.35", "500", map.current)
    }, [newLoc, loaded]);

    React.useEffect(() => {
        //console.log("Again");
        if (map.current && newLoc && prevNewLoc.current !== newLoc ) {
        //if (map.current && newLoc) {
            
            
            newCords(newLoc)
            prevNewLoc.current = newLoc; 
        }

    }, [newLoc, newCords])

    

    return <div style={{ height: "calc(100dvh - 330px)", width: "100%" }} ref={mapContainerRef} />
}

function LocationPicker({ placeData, closeModal, newPlaceData }) {
    //console.log(placeData);
    //const [rowData, setRowData] = React.useState({});
    const [latLng, setLatLng] = React.useState({});
    const [labelRadius, setLableRadius] = React.useState({});
    const [showSnack, setShowSnack] = React.useState(false);
    //const [confirm, setConfirm] = React.useState(false);
    //const [compareObject, setCompareObject] = React.useState(placeData ? placeData : {});

    /*const matches = (obj, source) =>
        Object.keys(source).every(
            key => obj.hasOwnProperty(key) && obj[key] === source[key]
        );
    const handleClose = () => {
        const newObj = { ...latLng, ...labelRadius, ...(placeData.id) ? { id: placeData.id } : {} };
        if (matches(compareObject, newObj)) {
            closeModal(false)
        } else {
            setConfirm(true)
        }
    };*/

    React.useEffect(() => {
        //console.log("config token", placeData)
        //config.accessToken = accessToken;
        if (Object.keys(placeData).length) {
            setLatLng({ lat: placeData.lat, lng: placeData.lng, rad: placeData.rad});
            setLableRadius({ label: placeData.label });
        }
        else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.log("Geolocation not supported");
            }

            function success(position) {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setLatLng({ lat: latitude, lng: longitude, rad: 500 });
                setLableRadius({ label: "" });
                //setCompareObject({ ...setCompareObject, lat: latitude, lng: longitude, label: "", rad: 500 })
                //console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            }
            function error() {
                setLatLng({ lat: 1, lng: 1, rad: 500 });
                setLableRadius({ label: "" });
                //setCompareObject({ ...setCompareObject, lat: 1, lng: 1, label: "", rad: 500 })
                console.log("Unable to retrieve your location");
            }
        }
    }, [placeData])

    const submitLocation = () => {
        if (latLng.lat === "" || latLng.lng === "" || labelRadius.label === "" || latLng.rad === "") {
            //setConfirm(false);
            setShowSnack(true);
        }
        else {
            newPlaceData({ ...latLng, ...labelRadius, id: placeData.id });
            //closeModal(false)
        }
    }


    const newData = React.useCallback((data) => {
        //setLatLng({...data, rad:latLng.rad})
        //console.log("updating control", data);
        setLatLng({...data, rad:latLng.rad})
    }, [latLng])


    /*const handleRetrieve = React.useCallback((res) => {
            const feature = res.features[0];
            setGeoCdding(feature.properties.full_address)
            console.log({ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] });
            setLatLng({ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] })
        
    }, [setGeoCdding, setLatLng]);*/

    return (

        <Box sx={{ width: '100%', height: "calc(100dvh - 150px)", bgcolor: 'background.paper', boxShadow: 0, p: 4 }} >
            <Snackbar open={showSnack} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={() => { setShowSnack(false) }} message={"All fields are mandatory!"} />
            <Grid container spacing={1}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField key={1} id="standard-label" value={labelRadius.label || ''} onChange={(e) => { setLableRadius({ ...labelRadius, label: e.target.value }) }} fullWidth label="Label" variant="standard" />
                </Grid>

                <Grid item xs={12}>
                    {/*<LocationPicker cords={{lat:rowData.lat, lng:rowData.lng}}/>*/}
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    <TextField key={2} id="standard-lat" value={latLng.lat || ''} fullWidth onChange={(e) => { setLatLng({ ...latLng, lat: e.target.value }) }} type="number" label="Latitude" variant="standard" />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    <TextField key={3} id="standard-lng" value={latLng.lng || ''} fullWidth onChange={(e) => { setLatLng({ ...latLng, lng: e.target.value }) }} type="number" label="Longitude" variant="standard" />
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                    <TextField key={4} id="standard-radius" value={latLng.rad || ''} onChange={(e) => { setLatLng({  ...latLng, rad: e.target.value }) }} type="number" fullWidth label="Radius" variant="standard" />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                    
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {Object.keys(latLng).length ? <PickerMap cords={latLng} newCords={newData}></PickerMap> : <div style={{ height: "calc(100dvh - 350px)", width: "100%" }}>Loading...</div>}
                </Grid>
                <Grid container sx={{ marginTop: 1, marginRight:5 }} direction="column" alignItems="end" justifyContent="right" >
                    <ButtonGroup variant="contained">
                        <Button size='small' onClick={submitLocation}>Save</Button>
                        <Button size='small' sx={{ bgcolor: "#fff", color: "#000" }} onClick={()=>{closeModal(true)}}>Cancel</Button>
                    </ButtonGroup>
                </Grid>
                {/*confirm ? <Dialog
                    open={confirm}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{labelRadius.label}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Save Changes?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={submitLocation}>Yes</Button>
                        <Button onClick={() => { setConfirm(false); closeModal(false) }}>No</Button>
                    </DialogActions>
                </Dialog> : <></>*/}
            </Grid>
        </Box>
    );
}

export default function Location() {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    const [places, setPlaces] = React.useState([]);
    const [snackBar, setSnackBar] = React.useState(false);
    const [autoComplete, setAutoComplete] = React.useState(false);
    const matches = useMediaQuery('(max-width:600px)');
    //const [tableRow, selectTableRow] = React.useState(false);
    const [rowData, setRowData] = React.useState({
        id: null,
        label: null,
        lat: null,
        lng: null,
        rad: null,
    });
    React.useEffect(() => {
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                "place": {
                    "list": {}
                }
            }
        })).then(res => {
            if (res && res.payload && !res.payload.error) {
                //console.log(res.payload.place.list);
                if (res.payload.place.list.length) {
                    setPlaces(res.payload.place.list);
                }

                //setUserDetail(res.payload.user.detailUser);
            }
        })
    }, [dispatch])

    const updatePlaceData = React.useCallback((data) => {
        var cont = true;

        if(!data.id)cont = false;
        for(var i in places){
            if(places[i].id === data.id){
                if(places[i].lat !== data.lat || places[i].lng !== data.lng || places[i].rad !== data.rad || places[i].label !== data.label){
                    cont = false;
                }
            }
        }
        
        if(cont)return;

        var place = {};
        if (data.id) {
            place["update"] = {
                ...data
            }
        } else {
            place["add"] = {
                "label": data.label,
                "lat": data.lat,
                "lng": data.lng,
                "rad": data.rad,
            }
        }

        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                place
            }
        })).then(res => {
            //console.log(res.payload);
            if (res.payload.place.update) {
                setSnackBar("Place " + res.payload.place.update[0].label + " Updated.")
            }
            else if (res.payload.place.add) {
                setSnackBar("New place " + res.payload.place.add[0].label + " Added.")
                setOpen(false);
                setAutoComplete(false);
            } else {
                setSnackBar("Error! adding/updating place.")
            }

            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",
                    "place": {
                        "list": {}
                    }
                }
            })).then(res => {
                if (res && res.payload && !res.payload.error) {
                    //console.log(res.payload.place.list);
                    setPlaces(res.payload.place.list);
                    //setUserDetail(res.payload.user.detailUser);
                }
            })
        })
    }, [dispatch, places]);

    const deletePlace = React.useCallback(() => {
        setConfirm(false);

        var place = { "list": {} };

        place["del"] = rowData.id;

        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                place
            }
        })).then(res => {
            //console.log(res.payload);
            if (res.payload.place.del) 
                {
                setOpen(false)
                setRowData({
                    id: null,
                    label: null,
                    lat: null,
                    lng: null,
                    rad: null,
                });
                setAutoComplete(false);
                setPlaces(res.payload.place.list);
                setSnackBar("Place " + rowData.label + " Deleted.")
            } else {
                setSnackBar("Error! adding/updating place.")
            }
        })
    }, [dispatch, rowData]);

    const handleClose = () => {
        setConfirm(false);
    };

    //const handleClose = () => setOpen(false);
    return (
        //console.log(rowData),
        <Box sx={{ display: "flex" }}>
            <Navbar />
            <Box component={"main"} sx={{ flexGrow: 1, marginTop: matches ? 8 : 9 }}>
                <Grid>
                    <Fab onClick={() => {
                        setRowData({}); setOpen(true)
                    }} sx={{ position: "fixed", right: 10, bottom: 20 }} size="small" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Autocomplete size='small'
                            disablePortal
                            id="combo-box-demo"
                            value={autoComplete || null}
                            //searchData([...searchItem].filter((loc) => loc.label === val)?[...searchItem].filter((loc) => loc.label === val):"") 
                            onChange={(e, val) => { setAutoComplete(val); setRowData(val ? [...places].filter((loc) => loc.label === val)[0] : ""); setOpen(val ? true : false) }}
                            options={places.map((a) => a.label)}
                            //options={places}
                            //getOptionLabel={(option) => option.label}
                            
                            sx={{ width: 300, display: 'inline-flex' }}
                            renderInput={(params) => <TextField {...params} label="Place" />}
                        />
                        {rowData && rowData.id ? <DeleteIcon onClick={() => setConfirm(true)} sx={{ cursor: "pointer" }} color='primary' /> : <></>}
                    </Grid>
                    <Grid container sx={{ marginTop: 1 }} direction="column" alignItems="end" justifyContent="right" >
                        {open ? <LocationPicker placeData={rowData} closeModal={() => { setOpen(false) }} newPlaceData={updatePlaceData} /> : <></>}
                    </Grid>
                    {confirm ? <Dialog
                        open={true}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Confirm Delete?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Are you sure want to delete Place {rowData.label}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={deletePlace}>Yes</Button>
                            <Button onClick={handleClose}>No</Button>
                        </DialogActions>
                    </Dialog> : <></>}

                    {/*open ? <LocationPicker placeData={rowData} closeModal={() => { setOpen(false) }} newPlaceData={updatePlaceData} /> : <></>*/}
                    <Snackbar open={snackBar ? true : false} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={() => { setSnackBar(false) }} message={snackBar} />
                </Grid>
            </Box>
        </Box>
    );
}