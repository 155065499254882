import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import user from './user'
import api from './api'

const reducer = combineReducers({
    user,
    api
})
const store = configureStore({
  reducer,
})
export default store;