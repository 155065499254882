import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    gridClasses,
    GridActionsCellItem,
    GridToolbar,
    GridRowEditStopReasons
} from '@mui/x-data-grid';
import Navbar from '../navbar';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPI } from "../../store/api";
import { useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


const ODD_OPACITY = 0.8;

const StripedGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: "#f8f8f8",
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.secondary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: "#B8B8B8",
        color: theme.palette.common.black,
        fontWeight: "bold"
    }
}));

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        
        const id = "new";//randomId();
        //setRows((oldRows) => [...oldRows, {id, isNew: true }]);

        setRows((oldRows) => {
            if(oldRows[0] && oldRows[0].isNew && oldRows[0].isNew === true){
                return oldRows;    
            }else{
                const updatedRows = structuredClone(oldRows);
                const newRow = { id, isNew: true };
                updatedRows.unshift(newRow);
                return updatedRows;
            }
            
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <Fab onClick={handleClick} sx={{ position: "fixed", right: 10, bottom: 8 }} size="small" color="primary" aria-label="add">
            <AddIcon />
        </Fab>
    );
}

export default function Group() {
    const [rows, setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [snackBar, setSnackBar] = React.useState(false);
    const [batch, setBatch] = React.useState(false);
    const [changes, setChanges] = React.useState(false);
    const dispatch = useDispatch()
    const [confirm, setConfirm] = React.useState(false);
    const [fields, setFields] = React.useState({
        label:""
    });
    const matches = useMediaQuery('(max-width:600px)');


    React.useEffect(() => {

        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                "group": {
                    "list": {}
                }
            }
        })).then(res => {
            setRows(res.payload.group.list);
        })
    }, [dispatch])

    const AddEditGroup = (data) => {

        let obj = {}
        var group = {}
        if (data.id !== "new") {
            for (var i in rows) {
                if (rows[i].id === data.id) {
                    if (rows[i].label !== data.label) {
                        obj.label = data.label;
                    }
                    if (Object.keys(obj).length) obj.id = rows[i].id;
                    break;
                }
            }
        } else {
            if (data.label) obj["label"] = data.label;
        }

        if (!Object.keys(obj).length) return;


        if (data.id !== "new") {
            group["update"] = {
                ...obj
            }
        } else {
            group["add"] = obj;
        }
    
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                group
            }
        })).then(res => {
            if (res.payload.group.update) {
                setSnackBar("Group " + data.label + " Updated.")
            }
            else if (res.payload.group.add) {
                setSnackBar("New Group " + data.label + " Added.")
            } else {
                setSnackBar("Error! adding/updating Group.")
            }

            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "token": "",
                    "group": {
                        "list": {}
                    }
                }
            })).then(res => {
                if (res && res.payload && !res.payload.error) {
                    if (res.payload.group.list.length) {
                        setRows(res.payload.group.list);
                    }
                }
            })
        })
    }

    const handleClose = () => {
        setConfirm(false);
    };

    const deleteGroup = React.useCallback(() => {
        var group = { "list": {} };

        group["del"] = confirm.map((cnf) => cnf.id);
        dispatch(getAPI({
            "method": "POST",
            "data": {
                "token": "",
                group
            }
        })).then(res => {
            if (res.payload.group.del) {
                setRows(res.payload.group.list);
                setSnackBar("Group(s) " + confirm.map((cnf) => cnf.label).toString() + " Deleted.")
                setConfirm(false);
            } else {
                setSnackBar("Error! Deleting Group(s).")
            }
        })
    }, [dispatch, confirm]);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        if(!Object.keys(rowModesModel).length){
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        }else{
            setSnackBar("Already another row is in editing mode.")
        }
    };

    const handleSaveClick = (id) => () => {
    
        const editedRow = rows.find((row) => row.id === id);
        
        if((fields.label && (fields.label !== editedRow.label))){
            setChanges({"id":id, "event":"save"});
        }else{
            setFields({
                label:"",
                groupID:"",
                device:""
            })
            setChanges(false);
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
        
        //let row = apiRef.current.getRow(id);
        //console.log(row);
        //console.log(rowModesModel);
        //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        
    };

    const handleDeleteClick = () => {
        //console.log("here",batch);
        //console.log(rows.filter((row) => batch.includes(row.id)))
        setConfirm(rows.filter((row) => batch.includes(row.id)));
        //setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        const editRow = rows.find((row) => row.id === id);
        if((fields.label && (fields.label !== editRow.label))){
            setChanges({"id":id, "event":"cancel"});
        }else{
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
            setFields({
                label:""
            })
            setChanges(false);
        }
        //return false
        //setChanges(true);
        
        
    };

    const YesEditCancel = ()=>{
        //console.log(changes)
        let id = changes.id;
        if(changes.event === "cancel"){
            //console.log(changes);
            
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        }else{
            //console.log("here");
            //AddEditAsset(changes.data);
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
        setChanges(false);
    }
    
    const NoEditCancel = ()=>{
        //console.log(changes)
        let id = changes.id;
        if(changes.event === "cancel"){
            //console.log(changes);
            setChanges(false);
            return false;
            
        }else{
            setChanges(false);
            
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        }
        //setChanges(false);
    }

    const processRowUpdate = (newRow) => {
    
        if ((newRow.id === "new") && (!newRow.label)) {
            setSnackBar("Group name is mandetory.")
            return false;
        }

        //setChanges({...changes, "data":newRow});
        AddEditGroup(newRow);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        //console.log(newRowModesModel);
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        {
            field: 'label',
            headerName: 'Label',
            flex: 1,
            minWidth: 120,
            //(matches)?("minWidth:120"):("flex: 1"),

            align: 'left',
            headerAlign: 'left',
            editable: true,
            renderEditCell: (params) => {
                const { value, api, id, field} = params;
                 return(
                    <TextField sx={{flex:1}} onChange={(event)=>{ api.setEditCellValue({id, field, value:event.target.value}); setFields((oldField)=>{ return {...oldField, "label":event.target.value }}) }} value={value}></TextField>
                )
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: "80",
            cellClassName: 'actions',
            getActions: ({id}) => {
                
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon color='primary' />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon color='primary' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
    ];

    return (
        <Box sx={{ display: "flex" }}>
            <Navbar />
            <Box component={"main"} sx={{ flexGrow: 1, overflow: "hidden", marginTop: matches ? 7 : 8 }}>
                <Box
                    sx={{
                        height: matches ? "calc(100dvh - 57px)":"calc(100dvh - 65px)",
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        }
                    }}
                >
                    <StripedGrid
                        //experimentalFeatures={{ ariaV7: true }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: "secondary.main",
                                opacity:0.8
                                
                            },
                            overflowX: "hidden"
                        }}
                        checkboxSelection
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        onCellDoubleClick={(param,e)=> e.stopPropagation()}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setBatch(newRowSelectionModel)
                        }}
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        //hideFooter
                        hideFooterPagination
                        processRowUpdate={processRowUpdate}
                        slots={{ toolbar: GridToolbar }}
                            slotProps={{
                            toolbar: {
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                                showQuickFilter: true,
                                
                            }
                        }}
                    />
                </Box>
                {confirm ? <Dialog
                    open={true}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Confirm Delete?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure want to delete selected Group(s) {confirm.map((cnf) => cnf.label).toString()}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteGroup}>Yes</Button>
                        <Button onClick={handleClose}>No</Button>
                    </DialogActions>
                </Dialog> : <></>}
                {changes ? <Dialog
                    open={true}
                    keepMounted
                    //onClose={NoEditCancel}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Confirm!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {changes.event === "cancel"?"Exit Unsaved?":"Save Changes?"}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={YesEditCancel}>Yes</Button>
                        <Button onClick={NoEditCancel}>No</Button>
                    </DialogActions>
                </Dialog> : <></>}
                <EditToolbar setRows={setRows} setRowModesModel={setRowModesModel} />
                {batch.length ? <Fab onClick={handleDeleteClick} sx={{ position: "fixed", right: 60, bottom: 8 }} size="small" color="primary" aria-label="delete"><DeleteIcon /></Fab> : <></>}
                <Snackbar open={snackBar ? true : false} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} onClose={() => { setSnackBar(false) }} message={snackBar} />
            </Box>
        </Box>
    );
}