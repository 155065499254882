import * as React from 'react';
//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { login } from "./store/user"
import { getAPI } from "./store/api";

import { useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { Navigate } from "react-router-dom";

import logo from './Assets/logo.png';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://smartPin.co/">
                SmartPin.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

//const defaultTheme = createTheme();

export default function SignIn() {
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("UserName/Password must not empty!");
    const dispatch = useDispatch()
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('email') || !data.get('password')) {
            setMessage("UserName/Password must not empty!");
            setOpen(true);
        }
        else {
            
            dispatch(getAPI({
                "method": "POST",
                "data": {
                    "login": {
                        "email": `${data.get('email')}`,
                        "password": `${data.get('password')}`
                    }
                }
            })).then(res =>{
                console.log(res);
                //console.log("check login");
                //if(res.payload && res.payload.login && res.payload.login.error){
                if(res.payload.login.error){
                    setMessage(res.payload.login.error);
                    setOpen(true);
                }
                else{
                    dispatch(login(res.payload));
                }
            })
        }
    }

    const responseMessage = (response) => {
        //console.log(jwt_decode(response.credential));
        dispatch(login(jwt_decode(response.credential)))
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const handleClose = (event, reason) => {
        setOpen(false);
    };

    return (

        //<ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    mt: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src={logo} width={200} alt={"logo"} />
                {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>*/}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container sx={{ mt: 3 }}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} ></GoogleLogin>
                        </Grid>
                        {/*<Grid item>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>*/}
                    </Grid>
                </Box>
            </Box>
            <Snackbar
                open={open}

                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={3000}

                onClose={handleClose}
                message={message}
            //action={action}
            />
            <Copyright sx={{ mt: 5, mb: 4 }} />
        </Container>
        //</ThemeProvider>
    );
}
