import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader(props) {
  //const [open, setOpen] = React.useState(false);
  /*const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };*/

  return (
    <div>
      <Backdrop
        sx={{ color: '#CD5C5C', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={props.show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}